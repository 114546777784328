import React from "react";

// Root Include
const Root = React.lazy(() => import("./pages/Home/indexRoot"));

//Main Index
const Main = React.lazy(() => import("./pages/Home/indexMain"));

const Wineries = React.lazy(() => import("./pages/Wineries/index"));

const Join = React.lazy(() => import("./pages/Join/Join"));

const JoinAd = React.lazy(() => import("./pages/Join/JoinAd"));

const Gift = React.lazy(() => import("./pages/Gift/Gift"));

const PaymentStatus = React.lazy(() => import("./pages/PaymentStatus/PaymentStatus"));

const routes = [
  
  //Index Main
  { path: "/index", component: Main, isTopbarDark: true },

  //Index root
  //{ path: "/", component: Root, exact: true },
  { path: "/partners", component: Wineries, startLightLogo: true },
  { path: "/join", component: Join, exact: true },
  { path: "/", component: JoinAd, startLightLogo: true },
  { path: "/gift", component: Gift, startLightLogo: true },
  { path: "/payment_status", component: PaymentStatus, exact: true },
];

export default routes;
