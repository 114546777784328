import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? loadStripe("pk_test_r1qd5NgKnpKa1MV4DHQat0si") : loadStripe("pk_live_UVjMmhBJKgA7luGkcU1C8qMR");

const app = (
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
